import React from "react"
import SingleMessage from "../components/sections/SingleMessage"
import Layout from "../components/common/Layout"
import { navigate } from "gatsby"

const Error = () => {
  return (
    <Layout>
      <SingleMessage
        message="This page doesn't exists!"
        btnLabel="Go home"
        onClick={() => navigate("/")}
      />
    </Layout>
  )
}

export function Head() {
  return <title>The Kitchen Guy</title>
}

export default Error
