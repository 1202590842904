import React from "react"
import styled from "styled-components"
import Section from "../common/Section"
import Title from "../common/Title"
import Button from "../common/Button"

const SingleMessage = ({ message, btnLabel, onClick }) => {
  return (
    <Wrapper>
      <Section>
        <Title sub={message} />
        <Button label={btnLabel} onClick={() => onClick()} />
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  height: calc(100vh - 10rem);
  display: flex;
  align-items: center;
  min-height: 400px;
`

export default SingleMessage
